// Generated by Framer (24f4d74)

import * as React from "react";
import { motion, LayoutGroup } from "framer-motion";
import { useOnVariantChange, useActiveVariantCallback, Image, addFonts, withCSS, addPropertyControls, ControlType, cx, useVariantState, CycleVariantState, RichText } from "framer";

const enabledGestures = {YMwmk5YRz: {hover: true}};

const cycleOrder = ["YMwmk5YRz"];

const variantClassNames = {YMwmk5YRz: "framer-v-1b7n5wg"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {type: "spring", ease: [0.44, 0, 0.56, 1], duration: 0.3, delay: 0, stiffness: 500, damping: 60, mass: 1}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

const useRandomID = () => {const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string; tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "YMwmk5YRz", title: ZpoZGqMs8 = "Experience", tap: d8z3WOs7A, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const { variants, baseVariant, gestureVariant, classNames, transition, setVariant, setGestureState } = useVariantState({defaultVariant: "YMwmk5YRz", variant, transitions, variantClassNames, enabledGestures, cycleOrder});
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant);

const onTap1b7n5wg = activeVariantCallback(async (...args) => {
if (d8z3WOs7A) {
const res = await d8z3WOs7A(...args);
if (res === false) return false;
}
})

const defaultLayoutId = useRandomID();

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-Hf5HX", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? "auto"}}>
<motion.div {...restProps} className={cx("framer-1b7n5wg", className)} style={{...style}} layoutId={"YMwmk5YRz"} transition={transition} layoutDependency={layoutDependency} background={null} data-highlight={true} data-framer-name={"Variant 1"} onTap={onTap1b7n5wg} ref={ref}><RichText className={"framer-18jq305"} style={{"--framer-paragraph-spacing": "0px", whiteSpace: "pre", "--extracted-1w3ko1f": "var(--token-54b298b7-7135-4031-be62-f3bbba2a7493, rgb(153, 153, 153)) "}} layoutId={"s7TkABR2D"} transition={transition} layoutDependency={layoutDependency} fonts={["Inter-Medium"]} withExternalLayout={true} verticalAlignment={"top"} __fromCanvasComponent={true} __htmlStructure={"<p style=\"--framer-line-height:1em; --framer-text-alignment:center;\"><span style=\"--framer-font-family:&quot;Inter-Medium&quot;, &quot;Inter&quot;, sans-serif; --framer-font-style:normal; --framer-font-weight:500; --font-selector:SW50ZXItTWVkaXVt; --framer-text-color:var(--extracted-1w3ko1f);\">{{ text-placeholder }}</span></p>"} htmlFromDesign={"<p style=\"--framer-line-height:1em; --framer-text-alignment:center;\"><span style=\"--framer-font-family:&quot;Inter-Medium&quot;, &quot;Inter&quot;, sans-serif; --framer-font-style:normal; --framer-font-weight:500; --font-selector:SW50ZXItTWVkaXVt; --framer-text-color:var(--extracted-1w3ko1f);\">Experience</span></p>"} textFromDesign={ZpoZGqMs8} variants={{"YMwmk5YRz-hover": {"--extracted-1w3ko1f": "var(--token-a27547a7-0864-4987-9209-62c170fce751, rgb(0, 0, 0)) "}}} {...addPropertyOverrides({"YMwmk5YRz-hover": {fonts: ["Inter-Medium"], __htmlStructure: "<p style=\"--framer-line-height:1em; --framer-text-alignment:center;\"><span style=\"--framer-font-family:&quot;Inter-Medium&quot;, &quot;Inter&quot;, sans-serif; --framer-font-style:normal; --framer-font-weight:500; --font-selector:SW50ZXItTWVkaXVt; --framer-text-color:var(--extracted-1w3ko1f);\">{{ text-placeholder }}</span></p>"}}, baseVariant, gestureVariant)}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-Hf5HX [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Hf5HX * { box-sizing: border-box; }", ".framer-Hf5HX .framer-1b7n5wg { position: relative; cursor: pointer; overflow: visible; width: min-content; height: min-content; display: flex; flex-direction: row; justify-content: center; align-content: center; align-items: center; flex-wrap: nowrap; gap: 0px; padding: 0px 0px 0px 0px; }", ".framer-Hf5HX .framer-18jq305 { position: relative; overflow: visible; width: auto; height: auto; flex: none; white-space: pre; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Hf5HX framer-1b7n5wg > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-Hf5HX framer-1b7n5wg > :first-child { margin-left: 0px; } .framer-Hf5HX framer-1b7n5wg > :last-child { margin-right: 0px; } }", ".framer-Hf5HX.framer-v-1b7n5wg .framer-1b7n5wg { cursor: pointer; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 16
 * @framerIntrinsicWidth 85
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"ExHCZXEZh":{"layout":["auto","auto"]}}}
 * @framerVariables {"ZpoZGqMs8":"title","d8z3WOs7A":"tap"}
 */
const FramernlDgzbBYp: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramernlDgzbBYp;

FramernlDgzbBYp.displayName = "Template/Simple resume 1 > Molecules > Navigation > CTAs";

FramernlDgzbBYp.defaultProps = {width: 85, height: 16};

addPropertyControls(FramernlDgzbBYp, {ZpoZGqMs8: {type: ControlType.String, title: "Title", defaultValue: "Experience", displayTextArea: false}, d8z3WOs7A: {type: ControlType.EventHandler, title: "Tap"}} as any);

addFonts(FramernlDgzbBYp, []);